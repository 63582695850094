.header {
  display: block;
  background-color: #ffffff;
  padding: 0.5rem 0;
  position: fixed;
  width: 100%;
  z-index: 3;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  .logo {
    line-height: 22px;
    img {
      height: 30px;
      width: auto;
    }
  }
  .company-name {
    font-family: great vibes;
    color: $theme-color;
    font-size: 20px;
    flex: 1;
    padding: 10px 0 0 5px;
  }
}

.login-button {
  background: $theme-color;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid;
  line-height: 1.5;
  color: $white-color;
  padding: 0.375rem 0.75rem;
  :focus {
    outline: none;
  }
}

.header-action-icon {
  color: $theme-color;
  font-size: 30px;
  cursor: pointer;
  margin: 0 5px;
}
