.react-datetime-picker__wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.react-datetime-picker__wrapper input {
  color: #495057;
}

.react-datetime-picker__wrapper select {
  color: #495057;
}
