.margin-auto {
  margin-right: auto;
  margin-left: auto;
}

body {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.form-control:focus {
  border-color: $theme-color;
}

footer {
  padding: 3rem 0;
  border-top: 1px solid #e7e7e7;
}

.remove-bullet {
  list-style-type: none;
  padding-left: 0;
}

.mulas-icon {
  color: $theme-color;
  margin-right: 4px;
}

.copyright {
  padding-top: 5px;
  border-top: 1px solid #e7e7e7;
}

a {
  color: $theme-color;
}
a:hover {
  color: $theme-color;
}

.fb-icon {
  color: $fb-color;
}

.page-header {
  padding: 2rem 0;
  border-bottom: 1px solid #e7e7e7;
}

.unset-margin {
  margin: unset;
}

.bullet-list {
  list-style-type: disc;
  padding-left: 10px;
}

.form-control:focus {
  box-shadow: unset;
}

.input-group-text {
  background-color: $theme-color;
  color: $white-color;
  cursor: pointer;
}

.ReactModal__Overlay {
  z-index: 3;
}

.slide-pane__header {
  background: $white-color !important;
  flex: 0 0 54px !important;
  height: 54px !important;
}

.slide-pane__close {
  padding: 16px 0 !important;
}

.slide-pane__close svg {
  font-size: 35px;
  width: auto !important;
  color: $theme-color !important;
}

.form-control.is-invalid {
  background-image: none;
}

.card-header {
  background-color: $theme-color;
  color: $white-color;
}

.dropdown {
  button {
    background-color: transparent;
    border-width: 0;
    padding: 0;
  }
  button:hover {
    background-color: transparent;
    border-width: 0;
    padding: 0;
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-width: 0;
    padding: 0;
  }
  .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-width: 0;
    padding: 0;
  }
  .btn:focus {
    box-shadow: none !important;
  }
  .dropdown-menu {
    top: 13px !important;
  }
  .dropdown-toggle:after {
    display: none;
  }
  .dropdown-item:active {
    background-color: $theme-color;
    color: white;
  }
}

.header-action-icons {
  justify-content: flex-end;
}

.page-content {
  padding: 2rem 0;
  border-bottom: 1px solid #e7e7e7;
  min-height: 59vh;
}

.order-card {
  margin-bottom: 1rem;
  cursor: pointer;
}

.order-card:hover {
  background-color: #d3d3d3;
}

.history-filters {
  position: sticky;
  top: 5rem;
}

.page-link {
  color: $theme-color;
}

.pagination {
  .active {
    background-color: $theme-color;
    border-color: $theme-color;
    .page-link {
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }
}

.page-link:hover {
  color: $theme-color;
}

.pagination {
  justify-content: center;
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $theme-color;
    -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  }
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $theme-color !important;
  background-color: $theme-color !important;
}

.custom-control-label {
  cursor: pointer;
}

.react-tel-input {
  margin-bottom: 10px !important;
  .form-control {
    width: 100% !important;
  }
}

.badge-mulas {
  background-color: $theme-color;
  color: $white-color;
  margin-left: -6px;
  height: 20px;
}

.badge-mulas-sm {
  background-color: $theme-color;
  color: $white-color;
  margin-left: -4px;
  height: 16px;
  padding: 0.15em 0.4em;
}

.big-screen-flag {
  height: 24px !important;
  width: 24px !important;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 3px;
}

.small-screen-flag {
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 4px;
}

.shopping_payment__apple_pay__button {
  display: inline-block;
  background-size: 100% 60%;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  min-height: 32px;
  max-height: 64px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-width: 0;
}

.PhoneInput {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
}

.PhoneInputInput:focus {
  outline: none !important;
}

.pay-button {
  border-radius: 4px;
  height: 34px;
  padding: 6px 12px;
  background-color: #ef692a;
  text-align: center;
  margin-bottom: 5px;
  color: #ffffff;
  cursor: pointer;
}

.paytrail-button {
  background-color: #E00094;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  line-height: unset;
  margin-right: 8px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input:checked + .slider {
    background-color: $theme-color;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $theme-color;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}

.ow{
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}