.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 1;
}

.ribbon {
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 15px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  right: -11px;
  top: 10px;
  width: 100px;
  height: 28px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #dedede;
  background-image: -webkit-linear-gradient(top, #ffffff 45%, #dedede 100%);
  background-image: -o-linear-gradient(top, #ffffff 45%, #dedede 100%);
  background-image: linear-gradient(to bottom, #ffffff 45%, #dedede 100%);
  background-repeat: repeat-x;
  ::before {
    left: 0;
  }
  ::after {
    right: 0;
  }
  ::before,
  ::after {
    content: '';
    border-top: 3px solid #9e9e9e;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
  }
}
.ribbon.green {
  background-color: #1abc9c;
  background-image: -webkit-linear-gradient(top, #1abc9c 45%, #17a689 100%);
  background-image: -o-linear-gradient(top, #1abc9c 45%, #17a689 100%);
  background-image: linear-gradient(to bottom, #1abc9c 45%, #17a689 100%);
  background-repeat: repeat-x;
  ::before,
  ::after {
    border-top: 3px solid #148f77;
  }
}
.ribbon.red {
  background-color: #bc1a3a;
  background-image: -webkit-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
  background-image: -o-linear-gradient(top, #a61733 45%, #bc1a3a 100%);
  background-image: linear-gradient(to bottom, #a61733 45%, #bc1a3a 100%);
  background-repeat: repeat-x;
  ::before,
  ::after {
    border-top: 3px solid #8f142c;
  }
}
