.disabled-track-button{
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: $secondary-text-color;
}

.active-track-button{
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: $success-color;
}

.vertical-line{
    width: 1px;
    margin: 2px 7px;
    height: 25px;
    background-color: $success-color;
}

.track-label {
    margin-left: 12px;
}