.star-rating-filled {
  fill: #fe5d0d;
}

.star-rating-blank {
  fill: #ffffff;
  stroke: #fe5d0d;
}

.flex-container {
  display: flex;
  justify-content: center;
}
