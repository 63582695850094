.rating-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.flex-content {
  max-width: 600px;
  min-width: 450px;
  padding: 2em;
  text-align: center;
}
.restaurant-name {
  text-transform: uppercase;
  font-size: 0.88em;
  line-height: 1.5em;
  color: #00000080;
  font-family: "Lato";
}

.rating-container h2 {
  font-size: 2.44em;
  font-weight: normal;
  color: #000000b2;
  margin-top: 1.5em;
  font-family: "Lato";
}

.rating-container h5 {
  font-size: 0.69em;
  line-height: 1.5em;
  margin-bottom: 4em;
  color: #000000b2;
  font-weight: normal;
  text-transform: uppercase;
  font-family: "Lato";
}

.rating-container button {
  margin-top: 4em;
  font-family: "Lato";
}

.rating-container button.btn-skip {
  display: block;
  float: right;
  font-size: 0.8em;
  color: #fe5d0d;
  text-transform: uppercase;
  margin: 0;
  border: none;
  background: none;
}

.rating-container textarea {
  width: 70%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #000000b2;
  font-size: 1em;
  padding: 0.5em;
}

@media screen and (max-width: 768px) {
  .flex-content {
    min-width: 300px;
    padding: 1.2em 0.5em;
    margin: auto 0.4em;
  }
}
