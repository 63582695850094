@import 'https://fonts.googleapis.com/css?family=Lato';
/* colligable  */
/* radio */
/* Styles for alert...
by the way it is so weird when you look at your code a couple of years after you wrote it XD */
/* radio box */
@keyframes "click-wave" {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}
html {
  scroll-behavior: smooth;
}
p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.sub-category {
  transition: transform 0.2s;
  &:hover {
    cursor: pointer;
    border-radius: 6px;
    transform: scale(1.006);
  }
}
.fa-caret-down {
  position: relative;
  top: 1px;
  left: 7px;
}
.content {
  > ul {
    > li {
      > span {
        float: right;
        font-size: 11px;
        font-weight: 700;
        margin-top: 12px;
        color: #a1d449;
      }
      padding: 4px;
      border-bottom: 1px solid #ddd;
      margin-bottom: unset;
    }
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  display: none;
  overflow: hidden;
}
.collapsible {
  cursor: pointer;
}
.cat-list {
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 15px;
  font-weight: 400;
  color: #565656;
}
li {
  > label {
    display: inline;
    margin-left: 10px;
    color: #565656;
  }
}
.border-none {
  border: none;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  -webkit-box-shadow: -5px -9px 17px -6px rgba(168, 168, 168, 1);
  -moz-box-shadow: -5px -9px 17px -6px rgba(168, 168, 168, 1);
  box-shadow: -5px -9px 17px -6px rgba(168, 168, 168, 1);
}
.form-check-label {
  font-size: 15px;
  font-weight: 400;
  margin: 6px;
  margin-left: 17px;
}
.content-box {
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  -webkit-box-shadow: -5px 15px 21px -6px rgba(168, 168, 168, 1);
  -moz-box-shadow: -5px 15px 21px -6px rgba(168, 168, 168, 1);
  box-shadow: -5px 15px 21px -6px rgba(168, 168, 168, 1);
  border-radius: 0px 0px 2px 2px;
  padding: 0;
}
.border-w-r {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
}
.sub-sub-options {
  padding: 15px 15px 15px 30px;
  border-top: 1px solid #ddd;
  margin: 0;
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: left;
}
h5 {
  > span {
    font-size: 14px;
    color: #656565;
    float: right;
  }
}
.item {
  margin-top: 10px;
}
body {
  padding-bottom: 100px;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
}
h2 {
  color: #aaaaaa;
}
.signature {
  margin: 10px auto;
  padding: 10px 0;
  width: 100%;
  p {
    text-align: center;
    font-family: Helvetica, Arial, Sans-Serif;
    font-size: 0.85em;
    color: #aaaaaa;
  }
  .much-heart {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    height: 10px;
    width: 10px;
    background: #ac1d3f;
    border-radius: 4px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    &::before {
      display: block;
      content: '';
      position: absolute;
      margin: auto;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background: #ac1d3f;
      top: -4px;
    }
    &::after {
      display: block;
      content: '';
      position: absolute;
      margin: auto;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background: #ac1d3f;
      top: -4px;
      bottom: 0;
      top: auto;
      left: -4px;
    }
  }
  a {
    color: #aaaaaa;
    text-decoration: none;
    font-weight: bold;
  }
}
.alert {
  box-sizing: border-box;
  background-color: #bdffe1;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 300;
  padding: 20px 40px;
  color: #333;
  h2 {
    font-size: 22px;
    color: #232323;
    margin-top: 0;
  }
  p {
    line-height: 1.6em;
    font-size: 18px;
  }
  a {
    color: #232323;
    font-weight: bold;
  }
}
.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 24px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
  &:hover {
    background: #9faab7;
  }
  &:checked {
    background: $theme-color;
    &::before {
      height: 40px;
      width: 24px;
      position: absolute;
      content: '✔';
      display: inline-block;
      font-size: 17.66667px;
      text-align: center;
      line-height: 27px;
    }
    &::after {
      -webkit-animation: click-wave 0.05s;
      -moz-animation: click-wave 0.05s;
      animation: click-wave 0.05s;
      background: $theme-color;
      content: '';
      display: block;
      position: relative;
      z-index: 100;
    }
  }
}
.option-input.radio {
  border-radius: 50%;
  &::after {
    border-radius: 50%;
  }
}
label {
  display: block;
  line-height: 40px;
  > span {
    position: relative;
    left: 26px;
    top: 5px;
  }
}
.counter {
  width: 150px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 50px;
    border: 0;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    background: $theme-color;
    color: #fff;
    appearance: none;
    outline: 0;
  }
  span {
    display: block;
    font-size: 25px;
    padding: 0 10px;
    cursor: pointer;
    color: $theme-color;
    user-select: none;
  }
}
.mxp {
  color: $theme-color;
}

@media (min-width: 560px) {
  .sticky-button {
    border-radius: 30px;
    border-width: 0;
    position: fixed;
    bottom: 85px;
    right: 24px;
    width: 60px;
    height: 60px;
    background-color: $theme-color;
  }
}

@media (max-width: 560px) {
  .sticky-button {
    border-radius: 30px;
    border-width: 0;
    position: fixed;
    bottom: 85px;
    right: 14px;
    width: 54px;
    height: 54px;
    background-color: $theme-color;
  }
}

.sticky-button-icon {
  color: white;
  font-size: 32px;
}

@media only screen and (max-width: 600px) {
  .sticky-info {
    background-color: $theme-color;
    position: fixed;
    bottom: 5px;
    color: white;
    padding: 8px 24px;
    border-radius: 8px;
    left: 25%;
  }
}

@media only screen and (min-width: 600px) {
  .sticky-info {
    display: none;
  }
}

.active-text {
  color: $theme-color;
}

@media only screen and (min-width: 600px) {
  .sticky-warn {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .sticky-warn {
    background-color: $theme-color;
    position: fixed;
    bottom: 0;
    color: white;
    padding: 8px 24px;
    border-radius: 8px;
    right: 0;
    z-index: 1;
    width: 100%;
  }
}

.sticky-content-button {
  color: $theme-color;
  background-color: $white-color;
  border: unset;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.25rem + 2px);
  padding-left: 8px;
  padding-right: 8px;
}

.text-error {
  color: red !important;
}

.new-row {
  width: calc(100% + 30px);
}

@media only screen and (max-width: 600px) {
  .mobile-list {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.product-list {
  cursor: pointer;
}

.mulas-btn {
  background-color: #ef692a;
}

.mulas-secondary-btn {
  background-color: #fffdd0;
}

.mulas-secondary-btn-text {
  color: #ef692a;
}

.mulas-btn-text {
  color: #ffffff;
}

.sticky-order {
  position: fixed;
  top: 60px;
  right: 12px;
  height: 60px;
  z-index: 2;
  border: 2px solid $theme-color;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  animation: mymove 2s infinite;
}

.react-datetime-picker {
  width: 100%;
}

@keyframes mymove {
  50% {
    box-shadow: 0 0 4px 4px #ef692a;
  }
}
