.button {
  background: #fe5d0d;
  border-radius: 100px;
  border-style: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.5em;
  min-width: 8em;
  padding: 8px 16px;
  text-transform: uppercase;
  text-align: center;
  width: auto;
}

.button:hover {
  background: #ec5408;
}

.button.inactive,
.button.disabled {
  background: rgba(0, 0, 0, 0.6);
}

.button span {
  display: inline-block;
  margin-left: 0.5em;
}
