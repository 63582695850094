.facebook-button {
  background-color: $fb-btn-color;
  color: $white-color;
  width: 100%;
  border-width: 0;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 0.25rem;
}

.google-button {
  background-color: $google-btn-color;
  color: $white-color;
  width: 100%;
  border-width: 0;
  padding: 5px;
  border-radius: 0.25rem;
}

.auth-input {
  margin-bottom: 10px;
}

.signin-button {
  background-color: $theme-color;
  color: $white-color;
  width: 100%;
  border-width: 0;
  padding: 5px;
  border-radius: 0.25rem;
}

.auth-footer {
  justify-content: center;
  padding: 0.15rem;
}

.action-link {
  color: $theme-color;
  cursor: pointer;
  font-size: 16px;
}

.form-separator {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  position: relative;
  margin: 30px 0;
  i {
    font-style: normal;
    font-size: 13px;
    color: #444;
    position: absolute;
    left: 50%;
    line-height: 30px;
    width: 30px;
    background-color: #fff;
    top: 50%;
    margin: -15px 0 0 -15px;
  }
}
