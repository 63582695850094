.banner {
  .italic-text {
    color: #fff;
    display: inline-block;
    font-family: great vibes;
    font-size: 60px;
    letter-spacing: 0.3px;
    line-height: 60px;
    width: 100%;
  }
  .highlighted-text {
    color: $theme-color;
  }
  h2 {
    font-size: 48px;
  }
  @media (max-width: 768px) {
    .search-button {
      background-color: $theme-color;
      color: $white-color;
      border: unset;
      border-radius: 0.25rem;
      height: calc(1.5em + 0.75rem + 2px);
      width: 100%;
    }
  }
  
  @media (min-width: 768px) {
    .search-button {
      background-color: $theme-color;
      color: $white-color;
      border: unset;
      border-radius: 0.25rem;
      height: calc(1.5em + 0.75rem + 2px);
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .col-md-5,
  .col-sm-12 {
    padding-right: 1px;
    padding-left: 1px;
  }
}

.food-section {
  padding: 1rem 0;
  border: 1px solid #e7e7e7;
  border-width: 1px 0 0 0;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
  }
}

.restaurant-card {
  margin-bottom: 30px;
  line-height: 1;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
  }
}
.restaurant-image {
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100px;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    img {
      width: 100%;
    }
  }
}

.dashboard-image {
  object-fit: cover;
}

@media (max-width: 768px) {
  .banner-small {
    height: 95vh;
    background-image: url('../images/new-4.svg');
    background-size: cover;
    background-position: center;
    width: 100vw;
  }
  .banner-large {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .banner-large {
    width: 100%;
    height: 70vh;
    background-image: url('../images/banner-large.png');
    background-size: cover;
    background-position: center;
  }
  .banner-small{
    display: none !important;
  }
}

.delivery-wrapper {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
}

.delivery-free-wrapper {
  padding: 5px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ef692a;
}

.restaurant-card {
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    margin-top: 5px;
  }
}

.busy-wrapper {
  position: absolute;
  z-index: 1;
}

.discount-wrapper {
  width: 100%;
  height: 20px;
  color: $theme-color;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

.vibes-text {
  font-size: 20px;
  color: $theme-color;
}
