.restaurant-banner {
  background-size: cover;
  background-color: #ffffff;
  background-position: center;
  padding: 6rem 0 1rem 0;
  position: relative;
  z-index: 0;
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(236, 235, 235, 0)),
      to(rgba(0, 0, 0, 0.6))
    );
    background-image: linear-gradient(
      180deg,
      rgba(236, 235, 235, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
  color: white;
  p,
  h2 {
    margin-bottom: unset;
  }
  span {
    margin-right: 10px;
  }
}

.restaurant-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.restaurant-nav-div {
  padding: 0.75rem 0 0;
  font-size: 0.875rem;
  border-bottom: 1px solid #e7e7e7;
  background-color: #ffffff;
  z-index: 2;
  li {
    padding: 8px;
    cursor: pointer;
  }
  .active {
    color: $theme-color;
    border-bottom: 2px solid $theme-color;
  }
  li:hover {
    color: $theme-color;
    border-bottom: 2px solid $theme-color;
  }
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
}

.product-div {
  margin-top: 2rem;
}

.restaurant-category-list {
  position: sticky;
  top: 7rem;
}

.restaurant-title {
  background: $theme-color;
  color: $white-color;
  padding: 0.75rem 1.25rem;
  margin-bottom: 5px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.category-name {
  padding: 5px;
  border-bottom: 1px solid $border-color;
  .active {
    color: $theme-color;
    border-bottom: 1px solid $theme-color;
  }
}

.category-name:hover {
  color: $theme-color;
  border-bottom: 1px solid $theme-color;
}

.product-card {
  margin-bottom: 10px;
  .card-header {
    background-color: $theme-color;
    color: $white-color;
  }
  .card-body {
    padding-bottom: unset;
  }
}

.products-list {
  list-style-type: none;
  padding: unset;
  li {
    border-bottom: 1px solid $border-color;
    margin-bottom: 0.5rem;
    padding-bottom: 5px;
    .descriptions {
      padding-right: 20px;
      flex: 1;
    }
    .add-btn {
      background: $theme-color;
      color: $white-color;
      padding: 0;
      width: 22px;
      height: 22px;
      border-width: 0;
    }
  }
  .no-border {
    border-bottom: unset;
  }
}

.categories-options {
  position: sticky;
  top: 6.25rem;
  margin-bottom: 5px;
  display: none;
  z-index: 2;
}

.categories-options-new{
  top: 3.2rem !important;
  padding-bottom: 4px !important;
}

@media (max-width: 768px) {
  .categories-list {
    display: none;
  }
  .product-div {
    margin-top: 0;
  }
  .categories-options {
    background-color: #fff;
    border: 0px;
    padding: 0;
    margin: 0 15px;
    display: -ms-flexbox;
    display: flex;
    /* overflow: hidden; */
    border-bottom: 1px solid #ddd;
    overflow-x: auto;
    p {
      padding: 0.25rem 1rem;
      border: 1px solid $border-color;
      display: -ms-flexbox;
      display: flex;
      border-radius: 50px;
      margin-right: 1rem;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      span {
        display: inline-block;
        max-width: 100%;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      margin-bottom: 0;
      margin-top: 15px;
    }
    .active {
      p {
        border-color: $theme-color;
      }
      span {
        color: $theme-color;
      }
    }
  }
}

.main-content {
  margin-bottom: 1rem;
}

.info-div {
  margin-top: 2rem;
  min-height: 36vh;
}

.time-label {
  display: inline-block;
  width: 100px;
}

.small-screen {
  display: none;
  .header-action-icon {
    font-size: 20px;
    margin: 4px 3px;
  }
  margin-left: 0;
  margin-right: 0;
  .logo {
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  .big-screen {
    display: none;
  }
  .small-screen {
    display: inline-block;
  }
}

.active-link {
  color: $theme-color;
  border-bottom: 2px solid $theme-color;
}

.discount-text {
  font-size: 30px;
  color: $theme-color;
  font-family: great vibes;
}

.new-card-header {
  color: $theme-color;
  padding: 5px;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid $theme-color;
}

.new-add-btn {
  background: $theme-color;
  color: $white-color;
  padding: 0;
  width: 22px;
  height: 22px;
  border-width: 0;
  border-radius: 11px;
}

.restaurant-menu {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.active-product {
  border: 1px solid $theme-color;
  border-radius: 4px;
  padding: 8px;
}

.selected-product {
  border-left: 3px solid $theme-color;
}

.menu-quantity {
  font-weight: bold;
  color: $theme-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.theme-color {
  color: $theme-color;
}

.disabled-button {
  background: $secondary-text-color!important;
}

.product-category-title{
  font-size: 12px;
  color: $secondary-text-color;
}

.product-category-addon{
  font-size: 14px;
  padding-left: 8px;
}
