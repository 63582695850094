.ant-btn-primary{
    border-color: #ef692a;
    background: #ef692a;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{

    background: #ef692a;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-color: #ef692a;
}

.ant-picker-input > input:hover{
    border-color: #ef692a; 
}

.ant-picker-input > input:focus{
    border-color: #ef692a; 
}

.ant-picker:hover, .ant-picker-focused{
    border-color: #ef692a;  
}

.ant-picker{
    width: 100%;
    margin-top: 4px;
}