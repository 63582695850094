.cart-title {
  color: $theme-color;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid $theme-color;
}

.cart-label {
  font-size: 14px;
}

.cart-item-row {
  margin: 10px 0 0 0;
  .col-sm-5,
  .col-sm-4,
  .col-sm-3 {
    padding-left: 10px;
    padding-right: 0;
  }
  .col-sm-3 {
    padding-right: 10px;
  }
}

.cart-qty {
  border-radius: 12px;
  border: 0.5px solid $theme-color;
  padding: 5px;
  font-size: 14px;
  margin-left: 8px;
  color: $theme-color;
}

.cart-qty-old {
  margin-right: 8px;
}

.dotted-border-bottom {
  border-bottom: 1px dotted #ced4da;
  padding-bottom: 10px;
}

.cart-addon-row {
  padding-left: 23px;
  .col-sm-5 {
    padding-left: 0;
  }
}

.solid-border-bottom {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 10px;
}

.checkout-button {
  background: $theme-color;
  border-color: $theme-color;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid;
  line-height: 1.5;
  color: $white-color;
  padding: 0.375rem 0.75rem;
  width: 100%;
  :focus {
    outline: none;
  }
}

.secondary-button {
  background: #d3d3d3;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  width: 100%;
  border-color: #d3d3d3;
  :focus {
    outline: none;
  }
}

.auto-button {
  width: auto;
}

.item-block {
  border: 1px solid $border-color;
  border-radius: 4px;
  margin: 10px 0;
  padding: 0 10px 10px 10px;
}

.cart-action {
  cursor: pointer;
  border: 1px solid black;
}

.cart-remove-action {
  cursor: pointer;
  border: 1px solid red;
  color: red;
  margin-left: 5px;
  margin-top: 5px;
}

.checkout-title-header {
  margin-bottom: 1rem;
}

.checkout-reataurant-title {
  font-size: 20px;
}

.cart-item-title {
  width: 120px;
  display: inline-block;
}
