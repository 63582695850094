@import '~antd/dist/antd.css';
@import '../css/bootstrap.min.css';
@import '../css/fontawesome.css';

@font-face {
  font-family: 'Great Vibes';
  src: url('../fonts/GreatVibes-Regular.ttf');
}

@import 'variables';

@import 'common';
@import 'dashboard';
@import 'header';
@import 'ribbon';
@import 'restaurants';
@import 'auth';
@import 'cart';
@import 'track';
@import 'custom';
@import 'override';
